import React from "react";

import { CoilProvider } from "@alxshelepenok/diesel";
import { ChakraProvider } from "@chakra-ui/react";
import { WrapRootElementBrowserArgs } from "gatsby";

import Fonts from "@/components/Fonts";
import { theme } from "@/theme";

const wrapRootElement = ({
  element,
}: WrapRootElementBrowserArgs): React.ReactElement => (
  <ChakraProvider theme={theme}>
    <Fonts />
    <CoilProvider>{element}</CoilProvider>
  </ChakraProvider>
);

export { wrapRootElement };
