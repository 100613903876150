import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  fonts: {
    heading: "Geomanist",
    body: "Geomanist",
  },
  Divider: {
    colorScheme: {
      green: {
        borderColor: "green.500",
      },
    },
  },
});
