import React from "react";

import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
  @font-face {
    font-family: "Geomanist";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/Geomanist-Medium.woff2') format('woff2');
  }
  /* latin-ext */
  @font-face {
    font-family: "Geomanist";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/Geomanist-Bold.woff2') format('woff2');
  }
  /* latin-ext */
  @font-face {
    font-family: "Geomanist";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('/fonts/Geomanist-Black.woff2') format('woff2');
  }
  /* latin-ext */
  @font-face {
    font-family: "Geomanist";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/Geomanist-Regular.woff2') format('woff2');
  }
  @font-face {
    font-family: "Geomanist";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/Geomanist-Light.woff2') format('woff2');
  }
  @font-face {
    font-family: "Geomanist";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('/fonts/Geomanist-ExtraLight.woff2') format('woff2');
  }
  `}
  />
);

export default Fonts;
